// html 使用
// maxScale 缩放最大倍速  默认5
// minScale 最小倍速 默认0.5
{
    /* <img src="" v-wheelScale:[initWheelScale]="wheelScaleChange" alt="" /> 
  const initWheelScale = reactive({ maxScale: 5, minScale: 0.5 }) */
  }
  export const initVWheelScale = (Vue) => {
    let oldScale = null //记录 上一次 scale 值
    Vue.directive("wheelScale", (el, binding) => {
      if (el) {
        el.onwheel = (e) => {
          const { maxScale = 10, minScale = 0.5 } = binding.arg || {}
          const cssVarName = "--scale"
          let _scale = el.style.getPropertyValue(cssVarName) || 1
          if (e.wheelDelta > 0) {
            _scale = _scale * 1 + 0.1
          } else {
            _scale = _scale * 1 - 0.1
          }
          // 现在缩放范围
          if (_scale > maxScale) {
            _scale = maxScale
          } else if (_scale < minScale) {
            _scale = minScale
          }
          // 设置 --scale 变量 缩放比例
          const setVarScale = (el, cssVarName) => {
            let cssText = el.style.cssText
            let cssTextList = cssText.split(";")
            let isExist = false
            let isExistIndex = -1
            for (let index = 0; index < cssTextList.length; index++) {
              const element = cssTextList[index]
              if (element.includes(cssVarName + ":")) {
                isExist = true
                isExistIndex = index
                break
              }
            }
            if (isExist) {
              cssTextList[isExistIndex] = `--scale: ${_scale}`
            } else {
              cssTextList.push(`--scale: ${_scale}`)
              //   el.setAttribute("style", `--scale: ${_scale}`)
            }
            cssText = cssTextList.join(";")
            el.style.cssText = cssText
          }
          // 设置 style.transform
          const setTransformCss = (el, cssVarName) => {
            let transformCssString = el.style.transform
            let regScaleGlobal = /scale\(.*?[ )]*[)]+[ ]*/g //匹配 Scale属性 全局
            if (regScaleGlobal.test(transformCssString)) {
              transformCssString = transformCssString.replace(
                regScaleGlobal,
                ` scale(var(${cssVarName})) `
              )
            } else {
              transformCssString += " " + `scale(var(${cssVarName}))`
            }
            el.style.transform = transformCssString
          }
          setVarScale(el, cssVarName)
          setTransformCss(el, cssVarName)
          // 缩放改变回调函数
          const wheelScaleChange = binding.value || null
  
          if (wheelScaleChange instanceof Function && _scale != oldScale) {
            oldScale = _scale
            wheelScaleChange({
              cssVarName,
              currentScale: _scale,
              maxScale,
              minScale,
            })
          }
        }
      }
    })
  }
  