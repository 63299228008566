export const initVDrag = (Vue) => {
    Vue.directive("drag", (el) => {
      const oDiv = el // 当前元素
      const ifMoveSizeArea = 20
      oDiv.onmousedown = (e) => {
        let box = oDiv
        while (  window.getComputedStyle(box).position !== "absolute" && box !== document.body ) {
          box = box.parentElement
        }
  
        document.onselectstart = () => {
          return false
        }
        if (!box.getAttribute("init_x")) {
          box.setAttribute("init_x", box.offsetLeft)
          box.setAttribute("init_y", box.offsetTop)
        }
  
        const initX = parseInt(box.getAttribute("init_x"))
        const initY = parseInt(box.getAttribute("init_y"))
  
        // 鼠标按下，计算当前元素距离可视区的距离
        const disX = e.clientX - box.offsetLeft
        const disY = e.clientY - box.offsetTop
        document.onmousemove = (e) => {
          // 通过事件委托，计算移动的距离
          // 因为浏览器里并不能直接取到并且使用clientX、clientY,所以使用事件委托在内部做完赋值
          const l = e.clientX - disX
          const t = e.clientY - disY
          // 计算移动当前元素的位置，并且给该元素样式中的left和top值赋值
          box.style.left = l + "px"
          box.style.top = t + "px"
          if (
            Math.abs(l - initX) > ifMoveSizeArea ||
            Math.abs(t - initY) > ifMoveSizeArea
          ) {
            box.setAttribute("dragged", "")
          } else {
            box.removeAttribute("dragged")
          }
        }
        document.onmouseup = (e) => {
          document.onmousemove = null
          document.onmouseup = null
          document.onselectstart = null
        }
        // return false不加的话可能导致黏连，拖到一个地方时div粘在鼠标上不下来，相当于onmouseup失效
        return false
      }
    })
  }
  
  